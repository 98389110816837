import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import Dashin from "../../img/dashin bank.jpg";
import CEB from "../../img/cbe.jpg";
import Skyline from "../../img/skyline hotel.jpg";
import Medhanit from "../../img/medhanit.jpg";
import Agoda from "../../img/agoda hotel.jpg";
import FoodDelivery from "../../img/beu food delivery.jpg";
import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{color: darkMode?'white': ''}}>Recent Projects</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
  <a href="https://dashenbanksc.com/consumer-loan/" target="_blank" rel="noopener noreferrer">
    <img src={Dashin} alt="Dashen Bank Consumer Loan" />
  </a>
</SwiperSlide>
<SwiperSlide>
  <a href="https://combanketh.et/" target="_blank" rel="noopener noreferrer">
    <img src={CEB} alt="Commercial Bank of Ethiopia" />
  </a>
</SwiperSlide>
<SwiperSlide>
  <a href="https://www.ethiopianskylighthotel.com/" target="_blank" rel="noopener noreferrer">
    <img src={Skyline} alt="Ethiopian Skylight Hotel" />
  </a>
</SwiperSlide>
<SwiperSlide>
  <a href="https://medanit.com/" target="_blank" rel="noopener noreferrer">
    <img src={Medhanit} alt="Medhanit" />
  </a>
</SwiperSlide>
<SwiperSlide>
  <a href="https://beudelivery.com/" target="_blank" rel="noopener noreferrer">
    <img src={FoodDelivery} alt="Beu Delivery" />
  </a>
</SwiperSlide>
<SwiperSlide>
  <a href="https://www.agoda.com/" target="_blank" rel="noopener noreferrer">
    <img src={Agoda} alt="Agoda" />
  </a>
</SwiperSlide>

      </Swiper>
    </div>
  );
};

export default Portfolio;
