import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";
import profilePic1 from "../../img/profile_new1.jpg";
import profilePic2 from "../../img/profile_new2.jpg";
import profilePic3 from "../../img/profile_new3.jpg";
import profilePic4 from "../../img/profile_new4.jpg";

const Testimonial = () => {
  const clients = [
    {
      img: profilePic1,
      review:
        "Working with Sweven Trading PLC has been a game-changer for our business. Their attention to detail and commitment to delivering exceptional work is unmatched. We couldn't be happier with the results!",
    },
    {
      img: profilePic2,
      review:
        "The team at Sweven Trading PLC consistently goes above and beyond our expectations. Their dedication to excellence shines through in every project they take on. Highly recommended!",
    },
    {
      img: profilePic3,
      review:
        "We've worked with many IT service providers, but none have delivered the quality and precision that Sweven Trading PLC consistently provides. Exceptional work every time!",
    },
    {
      img: profilePic4,
      review:
        "From start to finish, Sweven Trading PLC exceeded our expectations. Their ability to understand our needs and deliver exceptional results is why we keep coming back.",
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from us...</span>
      <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
      <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
